@import '_formlayout';

//ログイン・会員登録共通
.member {
    #contents {
        .wrapper {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            .main_content {
                .page_ttl {
                    //padding: 55px 0 0;
                    font-size: 28px;
                    line-height: 48px;
                    letter-spacing: 0.1em;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }
}
//ログイン
#member_login {
    #contents {
        .wrapper {
            .main_content {
                .inner {
                    display: flex;
                    justify-content: center;
                    align-items: stretch;
                    flex-wrap: wrap;
                    margin-top: 37px;
                    >div {
                        width: 460px;
                        margin: 0 40px;
                        padding-bottom: 26px;
                        .blk_ttl {
                            margin-bottom: 52px;
                            font-size: 24px;
                            line-height: 75px;
                            letter-spacing: 0.025em;
                            text-align: center;
                            font-weight: bold;
                            border-bottom: 1px solid #000;
                        }
                        &.members {
                            input[type="text"] {
                                display: block;
                                width: 340px;
                                height: 50px;
                                margin: 0 auto 22px;
                                padding: 0 10px;
                                line-height: 50px;
                            }
                            .save_chk {
                                width: 340px;
                                margin: 0 auto 40px;
                                font-size: 1.3rem;
                                line-height: 21px;
                                letter-spacing: 0;
                            }
                            .btn_blk {
                                text-align: center;
                                margin-bottom: 31px;
                            }
                            .pass_lost {
                                text-align: center;
                                a {
                                    font-size: 16px;
                                    line-height: 29px;
                                    letter-spacing: 0.05em;
                                    img {
                                        height: 16px;
                                        width: auto;
                                        margin-left: 13px;
                                        vertical-align: text-top;
                                    }
                                }
                            }
                        }
                        &.newbie {
                            p {
                                padding-left: 15px;
                                padding-right: 15px;
                                font-size: 1.6rem;
                                line-height: 24px;
                                letter-spacing: 0.025em;
                                font-weight: bold;
                            }
                            .benefits {
                                margin: 22px 0 28px;
                                padding: 0 80px;
                                ul {
                                    li {
                                        font-size: 16px;
                                        line-height: 20px;
                                        letter-spacing: 0;
                                        font-weight: bold;
                                        vertical-align: bottom;
                                        &::before {
                                            content: "";
                                            display: inline-block;
                                            width: 20px;
                                            height: 20px;
                                            margin-right: 10px;
                                            border-radius: 50%;
                                            border: 1px solid #c02d28;
                                            background-color: #c02d28;
                                            background-image: url(../../img/common/check_w.png);
                                            background-size: 11px 8px;
                                            background-position: center center;
                                            background-repeat: no-repeat;
                                        }
                                        &+li {
                                            margin-top: 16px;
                                        }
                                    }
                                }
                            }
                            .btn_blk {
                                text-align: center;
                                margin-bottom: 31px;
                            }
                        }
                        &.external {
                            margin-top: 27px;
                            .blk_ttl {
                                margin-bottom: 28px;
                            }
                            p {
                                margin-bottom: 38px;
                                font-size: 1.4rem;
                                line-height: 24px;
                                letter-spacing: 0.025em;
                                font-weight: bold;
                                text-align: left;
                            }
                            .btn_blk {
                                text-align: center;
                                a{
                                    display: inline-block;
                                    width: 380px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
//入力
#member_input {
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="email"],
    select{
        line-height: 40px;
        padding: 0 10px;
        &.birth_year, &.birth_month{
            &+span{
                left: 0;
                font-weight: normal;
            }
        }
    }
    #contents {
        .wrapper {
            .main_content {
                width: 96%;
                max-width: 840px;
                margin: 0 auto;
                .consent_head {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 20px 0 0;
                    p {
                        font-size: 1.4rem;
                        line-height: 1.5;
                        text-align: left;
                        vertical-align: middle;
                        font-weight: bold;
                    }
                }
                .consent_chk {
                    padding-top: 28px;
                    text-align: center;
                    #consent:checked~.btn_blk {
                        button {
                            opacity: 1;
                            pointer-events: inherit;
                        }
                    }
                    .btn_blk {
                        padding-top: 50px;
                        text-align: center;
                        button {
                            opacity: 0.2;
                            pointer-events: none;
                        }
                    }
                }
                .receive_setting {
                    padding: 20px 0 0 0;
                    p {
                        text-align: center;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.1em;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
//確認
#member_confirm {
    #contents {
        .wrapper {
            .main_content {
                .page_ttl {
                    margin-bottom: 73px;
                }
                .head_txt {
                    font-size: 1.8rem;
                    line-height: 28px;
                    padding: 0 0 15px;
                    letter-spacing: 0.05em;
                    font-weight: normal;
                    text-align: left;
                    max-width: 740px;
                    margin: 0 auto;
                }
                .info_table {
                    width: 740px;
                    margin: 0 auto;
                    padding: 20px 0 30px;
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                    .prop_blk {
                        width: 200px;
                        padding-left: 20px;
                        font-size: 1.4em;
                        line-height: 36px;
                        letter-spacing: 0.1em;
                        font-weight: bold;
                        text-align: left;
                        vertical-align: top;
                    }
                    .val_blk {
                        width: 428px;
                        padding-left: 43px;
                        font-size: 1.4rem;
                        line-height: 36px;
                        letter-spacing: 0.1em;
                    }
                    .chenge_btn_blk {
                        width: 112px;
                        padding-right: 12px;
                    }
                }
                .btn_blk {
                    padding-top: 100px;
                    text-align: center;
                }
            }
        }
    }
}
//完了
#member_complete {
    #contents {
        .wrapper {
            .main_content {
                width: 100%;
                .thx_txt {
                    padding-top: 73px;
                    font-size: 1.8rem;
                    letter-spacing: 0.025em;
                    font-weight: normal;
                    text-align: center;
                }
                .inquiry {
                    width: 664px;
                    margin: 65px auto 83px;
                    padding: 56px 0 60px;
                    background: #eeeeee;
                    p {
                        margin-bottom: 43px;
                        font-size: 1.6rem;
                        line-height: 28px;
                        letter-spacing: 0.025em;
                        text-align: center;
                    }
                }
                .link_blk {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 800px;
                    margin: 0 auto 80px;
                    a {
                        margin: 0 auto;
                    }
                }
                .btn_blk {
                    a {
                        margin: 0 auto;
                    }
                    &.back_btn {
                        margin-top: 80px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1220px) and (min-width: 751px) {
    #member_login {
        #contents {
            .wrapper {
                width: 100%;
                padding: 0 3vw;
                .main_content {
                    .inner {
                        display: flex;
                        justify-content: center;
                        align-items: stretch;
                        flex-wrap: wrap;
                        margin-top: 37px;
                        >div {
                            width: 42vw;
                            margin: 0;
                            padding-bottom: 26px;
                            .blk_ttl {
                                margin-bottom: 52px;
                                font-size: 24px;
                                line-height: 75px;
                                letter-spacing: 0.025em;
                                text-align: center;
                                font-weight: bold;
                                border-bottom: 1px solid #000;
                            }
                            &.members {
                                margin-right: 5vw;
                                input[type="text"] {
                                    display: block;
                                    width: 100%;
                                    height: 50px;
                                    margin: 0 auto 22px;
                                }
                                .save_chk {
                                    width: 90%;
                                    margin: 0 auto 40px;
                                    font-size: 1.3rem;
                                    line-height: 21px;
                                    letter-spacing: 0;
                                }
                                .btn_blk {
                                    text-align: center;
                                    margin-bottom: 31px;
                                }
                                .pass_lost {
                                    text-align: center;
                                    a {
                                        font-size: 16px;
                                        line-height: 29px;
                                        letter-spacing: 0.05em;
                                        img {
                                            height: 16px;
                                            width: auto;
                                            margin-left: 13px;
                                            vertical-align: text-top;
                                        }
                                    }
                                }
                            }
                            &.newbie {
                                p {
                                    padding-left: 8px;
                                    padding-right: 8px;
                                    font-size: 1.4rem;
                                    line-height: 24px;
                                    letter-spacing: 0.025em;
                                    font-weight: bold;
                                }
                                .benefits {
                                    width: 100%;
                                    margin: 22px 0 28px;
                                    padding: 0 20 / 1220 * 100vw;
                                    ul {
                                        li {
                                            font-size: 16px;
                                            line-height: 20px;
                                            letter-spacing: 0;
                                            font-weight: bold;
                                            &::before {
                                                content: "";
                                                display: inline-block;
                                                width: 20px;
                                                height: 20px;
                                                margin-right: 10px;
                                                border-radius: 50%;
                                                border: 1px solid #c02d28;
                                                background-color: #c02d28;
                                                background-image: url(../../img/common/check_w.png);
                                                background-size: 11px 8px;
                                                background-position: center center;
                                                background-repeat: no-repeat;
                                            }
                                            &+li {
                                                margin-top: 16px;
                                            }
                                        }
                                    }
                                }
                                .btn_blk {
                                    text-align: center;
                                    margin-bottom: 31px;
                                }
                            }
                            &.external {
                                width: 55%;
                                margin-top: 27px;
                                .blk_ttl {
                                    font-size: 2.2rem;
                                    margin-bottom: 28px;
                                }
                                p {
                                    margin-bottom: 38px;
                                    font-size: 1.4rem;
                                    line-height: 24px;
                                    letter-spacing: 0.025em;
                                    font-weight: bold;
                                }
                                .btn_blk {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
    
            }
        }
    }
    #member_input {
        #contents {
            .wrapper {
                width: 100%;
                padding: 0 3vw;
            }
        }
    }
    #member_confirm {
        #contents {
            .wrapper {
                width: 100%;
                padding: 0 3vw;
               .main_content {
                    .page_ttl {
                        margin-bottom: 40px;
                    }
                    .head_txt {
                        font-size: 1.8rem;
                        line-height: 28px;
                        padding: 0 0 15px;
                        letter-spacing: 0.05em;
                        font-weight: normal;
                        text-align: left;
                    }
                    .info_table {
                        width: 100%;
                        margin: 0 auto;
                        padding: 20px 0 30px;
                        border-top: 1px solid #000;
                        border-bottom: 1px solid #000;
                        .prop_blk {
                            width: 200px;
                            padding-left: 20px;
                            font-size: 1.4em;
                            line-height: 36px;
                            letter-spacing: 0.1em;
                            font-weight: bold;
                            text-align: left;
                            vertical-align: top;
                        }
                        .val_blk {
                            width: 428px;
                            padding-left: 43px;
                            font-size: 1.4rem;
                            line-height: 36px;
                            letter-spacing: 0.1em;
                        }
                        .chenge_btn_blk {
                            width: 112px;
                            padding-right: 12px;
                        }
                    }
                    .btn_blk {
                        padding-top: 100px;
                        text-align: center;
                    }
                }
            }
        }
    }
    #member_complete  {
        #contents {
            .wrapper {
                width: 100%;
                padding: 0 3vw;
                .main_content {
                    width: 100%;
                    .thx_txt {
                        padding-top: 94px;
                        font-size: 1.8rem;
                        letter-spacing: 0.025em;
                        text-align: center;
                    }
                    .inquiry {
                        width: 664px;
                        margin: 88px auto 50px;
                        padding: 56px 0 60px;
                        background: #eeeeee;
                        p {
                            margin-bottom: 43px;
                            font-size: 1.4rem;
                            line-height: 28px;
                            letter-spacing: 0.025em;
                            text-align: center;
                        }
                    }
                    .link_blk {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin: 0 0 50px;
                        a {
                            margin: 0 20px;
                        }
                    }
                    .btn_blk {
                        a {
                            margin: 0 auto;
                        }
                        &.back_btn {
                            margin-top: 50px;
                        }
                    }
                }
            }
        }
    }
}
//SP
@media only screen and (max-width: 750px) {
//ログイン・会員登録共通
.member {
    #contents {
        .wrapper {
            width: 100%;
            padding: 0 40/750*100vw;
            max-width: 1200px;
            margin: 0 auto;
            .main_content {
                .page_ttl {
                    padding: 80 * 100vw / 750 0 0;
                    font-size: 40 * 100vw / 750;
                    line-height: 1.4;
                    letter-spacing: 0.1em;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }
}
//ログイン
#member_login {
    #contents {
        .wrapper {
            .main_content {
                .inner {
                    display: flex;
                    justify-content: center;
                    align-items: stretch;
                    flex-wrap: wrap;
                    margin-top: 37 * 100vw / 750;
                    >div {
                        width: 670 * 100vw / 750;
                        margin: 0;
                        padding-bottom: 26 * 100vw / 750;
                        .blk_ttl {
                            margin-bottom: 52 * 100vw / 750;
                            font-size: 1.6rem;
                            line-height: 110 * 100vw / 750;
                            letter-spacing: 0.025em;
                            text-align: center;
                            font-weight: bold;
                            border-bottom: 1px solid #000;
                        }
                        &.members {
                            input[type="text"] {
                                display: block;
                                width: 590 / 750 * 100vw;
                                height: 80 * 100vw / 750;
                                margin: 0 auto 40 * 100vw / 750;
                                line-height: 80 * 100vw / 750;
                            }
                            .save_chk {
                                width: 590 * 100vw / 750;
                                margin: 0 auto 40 * 100vw / 750;
                                font-size: 13 * 100vw / 750;
                                line-height: 21 * 100vw / 750;
                                letter-spacing: 0;
                                .input_check:checked + .input_label::before{
                                    background-size: 11px 8px;
                                }
                                label[for="consent"]{
                                    display: inline-flex;
                                    margin-right: 0;
                                    line-height: 20px;
                                    &::before{
                                        width: 20px;
                                        height: 20px;
                                        margin-right: 10px;
                                    }
                                }
                            }
                            .btn_blk {
                                text-align: center;
                                margin-bottom: 5.86667vw;
                            }
                            .pass_lost {
                                text-align: center;
                                a {
                                    font-size: 24 * 100vw / 750;
                                    line-height: 29 * 100vw / 750;
                                    letter-spacing: 0.05em;
                                    img {
                                        height: 24 * 100vw / 750;
                                        width: auto;
                                        margin-left: 26 * 100vw / 750;
                                        vertical-align: text-top;
                                    }
                                }
                            }
                        }
                        &.newbie {
                            p {
                                width: 100%;
                                padding-left: 0;
                                padding-right: 0;
                                margin: 0 auto;
                                font-size: 1.4rem;
                                line-height: 42 * 100vw / 750;
                                letter-spacing: 0.025em;
                                font-weight: bold;
                                margin-bottom: 26px;
                            }
                            .benefits {
                                width: 100%;
                                padding: 0;
                                margin: 22 * 100vw / 750 0 28 * 100vw / 750;
                                ul {
                                    li {
                                        font-size: 4vw;
                                        letter-spacing: 0;
                                        margin-bottom: 5.06667vw;
                                        line-height: 60 / 750 * 100vw;
                                        vertical-align: bottom;
                                        padding: 0 6.66667vw;
                                        &::before {
                                            content: "";
                                            display: inline-block;
                                            width: 60 / 750 * 100vw;
                                            height: 60 / 750 * 100vw;
                                            margin-right: 25 * 100vw / 750;
                                            border-radius: 50%;
                                            border: 1px solid #c02d28;
                                            background-color: #c02d28;
                                            background-image: url(../../img/common/check_w.png);
                                            background-size: 33 * 100vw / 750 24 * 100vw / 750;
                                            background-position: center center;
                                            background-repeat: no-repeat;
                                            flex: none;
                                        }
                                        &+li {
                                            margin-top: 5.06667vw;
                                        }
                                    }
                                }
                            }
                            .btn_blk {
                                text-align: center;
                                margin-bottom: 31 * 100vw / 750;
                                a{
                                    margin-top: 5.33333vw;
                                }
                            }
                        }
                        &.external {
                            margin-top: 0;
                            .blk_ttl {
                                margin-bottom: 28 * 100vw / 750;
                            }
                            p {
                                width: 100%;
                                margin: 0 auto 38 * 100vw / 750;
                                font-size: 1.4rem;
                                line-height: 42 * 100vw / 750;
                                letter-spacing: 0.025em;
                                font-weight: bold;
                                text-align: left;
                            }
                            .btn_blk {
                                text-align: center;
                                a{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
//入力
#member_input {
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="email"],
    select{
        line-height: 36px;
        padding: 0 10px;
        &.birth_year, &.birth_month{
            width: 36%;
            &+span{
                position: static;
                font-weight: normal;
            }
        }
    }
    #contents {
        .wrapper {
            padding: 0;
            .main_content {
                width: 100%;
                .consent_head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20 * 100vw / 750 20px 0;
                    p {
                        width: 85%;
                        font-size: 1.4rem;
                        line-height: 1.5;
                        text-align: left;
                        vertical-align: middle;
                        font-weight: bold;
                    }
                }
                .consent_chk {
                    padding-top: 28 * 100vw / 750;
                    text-align: center;
                    .input_label{
                        line-height: 20px;
                        margin-right: 0;
                        &::before{
                            vertical-align: bottom;
                        }
                    }
                    #consent:checked~.btn_blk {
                        button {
                            background: #000;
                            pointer-events: inherit;
                        }
                    }
                    .btn_blk {
                        padding: 50 * 100vw / 750 20px 0;
                        text-align: center;
                        button {
                            width: 100%;
                            height: 160 * 100vw / 750;
                            margin: 0 auto;
                            font-size: 36 * 100vw / 750;
                            line-height: 1;
                            letter-spacing: 0.025em;
                            font-weight: bold;
                            pointer-events: none;
                        }
                    }
                }
                .receive_setting {
                    padding: 40 * 100vw / 750 20px 0;
                    p {
                        text-align: left;
                        font-size: 28 * 100vw / 750;
                        line-height: 42 * 100vw / 750;
                        letter-spacing: 0.1em;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
//確認
#member_confirm {
    #contents {
        .wrapper {
            .main_content {
                .page_ttl {
                    margin-bottom: 8vw;
                }
                .head_txt {
                    font-size: 1.4rem;
                    line-height: 38 * 100vw / 750;
                    letter-spacing: 0.05em;
                    font-weight: normal;
                    text-align: left;
                    padding: 0 0 15 * 100vw / 750;
                }
                .info_table {
                    width: 100%;
                    margin: 0 auto;
                    padding: 20 * 100vw / 750 0 0;
                    border-top: 1px solid #000;
                    border-bottom: none;
                    .prop_blk {
                        width: 40%;
                        padding: 10px 0 10px 20 * 100vw / 750;
                        font-size: 1.2rem;
                        line-height: 54 * 100vw / 750;
                        letter-spacing: 0.1em;
                        font-weight: bold;
                        text-align: left;
                        border-bottom: 1px solid #eeeeee;
                    }
                    .val_blk {
                        width: 60%;
                        padding: 10px 0 10px 50 * 100vw / 750;
                        font-size: 1.2rem;
                        line-height: 54 * 100vw / 750;
                        letter-spacing: 0.1em;
                        border-bottom: 1px solid #eeeeee;
                    }
                    .chenge_btn_blk {
                        display: none;
                    }
                }
                .chenge_btn_blk {
                    width: 100%;
                    padding: 0 0 50 * 100vw / 750;
                    margin: 6.66667vw 0 0;
                    border-bottom: 1px solid #000;
                    text-align: left;
                    &+div{
                        margin: 6.66667vw 0 0;
                        padding: 0;
                    }
                }
                .btn_blk {
                    padding: 100 * 100vw / 750 0 0;
                    text-align: center;
                }
            }
        }
    }
}
//完了
#member_complete {
    #contents {
        .wrapper {
            .main_content {
                width: 100%;
                .thx_txt {
                    padding-top: 8vw;
                    font-size: 1.6rem;
                    line-height: 1.6;
                    letter-spacing: 0.025em;
                    text-align: center;
                }
                .inquiry {
                    width: 100vw;
                    margin: 40 * 100vw / 750 0 83 * 100vw / 750 -40 * 100vw / 750;
                    padding: 56 * 100vw / 750 40 * 100vw / 750 60 * 100vw / 750;
                    background: #eeeeee;
                    p {
                        margin-bottom: 43 * 100vw / 750;
                        font-size: 1.4rem;
                        line-height: 1.75;
                        letter-spacing: 0.025em;
                        text-align: left;
                    }
                }
                .link_blk {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                    a {
                        margin: 0 auto 40 * 100vw / 750;
                    }
                }
                .btn_blk {
                    width: 100%;
                    a {
                        margin: 0 auto;
                    }
                    &.back_btn {
                        margin-top: 40 * 100vw / 750;
                    }
                }
            }
        }
    }
}
}