body .input_form_link {
  color: #000;
  text-decoration: underline;
}

body .input_form_title {
  padding-bottom: 8px;
  position: relative;
  border-bottom: 1px solid #000;
}

body .input_form_title h2 {
  font-size: 1.8rem;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

body .input_form_title p {
  margin-top: 15px;
  font-size: 1.2rem;
  line-height: 2;
}

body .input_form_title p small {
  display: block;
  font-size: inherit;
  line-height: inherit;
}

body .input_form_title .right {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

body {
  select {
    font-size: 1.4rem;
    width: 350px;
    height: 40px;
    border: 1px solid #d4d4d4;
    padding: 10px;
    border-radius: 0;
    &:focus {
      outline: none;
      border: 1px solid #c02d28;
    }
    + span {
      font-size: 1.4rem;
      font-weight: bold;
      position: relative;
      left: -30px;
      z-index: -1;
      transform: scale(1.2, 1);
      display: inline-block;
    }
    &.select_short {
      width: 160px;
    }
    &.select_date {
      + span {
        left: 0;
      }
    }
  }

  .input_form_text {
    line-height: 1.5;
    font-size: 1.4rem;
  }

  .input_form_table {
    border-collapse: collapse;
    width: 100%;

    tr {
      border-top: 1px solid #ccc;

      &:first-child {
        border-top: none;
      }
    }

    .input_form_no_border {
      border-top: none;
    }
    .input_form_border {
      border-top: 1px solid #ccc !important;
      border-bottom: 1px solid #ccc !important;
    }
    .form_border_dashed {
      border-top: 1px dashed #ccc !important;
    }
    .form_border_bottom {
      border-bottom: 1px solid #000;
    }

    .input_form_verticalTop {
      padding: 30px 20px 30px 0;
      vertical-align: top;
    }

    th {
      font-size: 1.4rem;
      line-height: 1.5;
      text-align: left;
      vertical-align: middle;
    }

    td {
      line-height: 1.5;
      vertical-align: middle;
    }

    .derivery_list > div {
      margin: 0 0 10px;
    }

    .point_total {
      padding-top: 5px;
      font-weight: normal;
      span {
        font-size: 1.4rem;
      }
      div {
        font-size: 1.6rem;
        .total_point {
          font-size: 2.4rem;
        }
      }
    }
  }

  .input_form_required {
    display: inline-block;
    width: 40px;
    margin-left: 5px;
    vertical-align: middle;
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    background-color: #c02d28;
  }

  .input_form_column {
    font-size: 0;

    dd {
      display: inline-block;
      width: 200px;
      vertical-align: top;

      + dd {
        margin-left: 35px;
      }
      &.input_form_point {
        width: 100%;
        .input_text {
          width: 100px;
          font-size: 2rem;
          font-weight: bold;
          margin-right: 10px;
        }
        .text_large {
          font-size: 1.6rem;
          margin-right: 20px;
        }
      }
      &.input_form_coupon {
        width: 100%;
        .input_text {
          width: 340px;
        }
      }
    }
  }

  .input_form_1column > td {
    width: 100%;
    padding: 0;
  }

  .input_form_list li {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .input_text,
  .input_text-month,
  .input_text-year,
  .input_text-passwd,
  .input_text-repasswd,
  .input_text-tel,
  .input_text-receipt,
  .input_text-email,
  .input_text-reemail,
  .input_text-addr1,
  .input_text-addr2,
  .input_text-addr3,
  .input_text-zip,
  .cartLoginFormBlock_input,
  .input_text-credit {
    font-size: 1.4rem;
    width: 200px;
    height: 40px;
    border: 1px solid #d4d4d4;
    padding: 10px;
    &:focus {
      outline: none;
      border: 1px solid #c02d28;
    }
  }
  .input_text-zip {
    margin-right: 0;
    + .input_button {
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
      width: 120px;
      height: 40px;
      border: 1px solid #000;
      background-color: #000;
      vertical-align: bottom;
      margin-left: 0 !important;
      position: relative;
      left: -4px;
      + .input_form_link {
        color: #000;
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }
  .cart_input_dropdown_text {
    font-size: 1.4rem;
  }
}

.has_error body {
  .input_text,
  .input_text-month,
  .input_text-year,
  .input_text-passwd,
  .input_text-repasswd,
  .input_text-tel,
  .input_text-receipt,
  .input_text-email,
  .input_text-reemail,
  .input_text-addr1,
  .input_text-addr2,
  .input_text-addr3,
  .input_text-zip,
  .cartLoginFormBlock_input {
    background-color: rgba(200, 40, 40, 0.2);
  }
}

body {
  .input_text-month {
    width: 55px;
  }

  .input_text-year {
    width: 100px;
  }

  .input_textarea {
    min-height: 120px;
    padding: 10px;
    border: 1px solid #d4d4d4;
  }

  .input_button {
    margin: 0 5px;
  }

  .input_check,
  .input_radio {
    display: none;
    + .input_label,
    + .cart_input_payment_label {
      margin-right: 26px;
      display: inline-flex;
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #bfbfbf;
        border-radius: 50%;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    &:checked {
      + .input_label,
      + .cart_input_payment_label {
        &::before {
          content: "";
          border: 1px solid #c02d28;
          background-color: #c02d28;
          background-image: url(../../img/common/check_w.png);
          background-size: 11px 8px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
    + .input_label_important {
      &::before {
        border: 1px solid #000;
      }
    }
    &:checked {
      + .input_label_important {
        &::before {
          border: 1px solid #c02d28;
        }
      }
    }
  }
}

* + body {
  .input_check,
  .input_radio {
    margin-left: 15px;
  }
}

body {
  .input_label {
    cursor: pointer;
  }

  .input_form_format {
    display: block;
    padding: 10px 0;
    font-size: 1.4rem;
  }

  .input_form_format-right {
    display: block;
    padding: 10px 0;
    font-size: 1.4rem;
    display: inline-block;
    padding: 0 8px;
  }

  .input_form_unit {
    display: inline-block;
    padding: 0 8px;
    font-size: 1.4rem;
  }

  .input_error {
    font-size: 1.2rem;
    color: #c02d28;
    font-weight: bold;
  }
}

* {
  letter-spacing: .025em;
}

.cart_input_payment_table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-top: 1px solid #ccc;

    &:first-child {
      border-top: none;
    }
  }
}

.cart_input_payment_label {
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
}

.cart_input_dropdown {
  border-top: 1px solid #d4d4d4;
  display: none;
}

.cart_input_payment_table .cart_input_dropdown {
  border-top: none;
}

.cart_input_dropdown_text img {
  display: block;
  max-width: 100%;
}

.input_form_table .cart_input_dropdown > td {
  width: 100%;
  padding: 0;
}

/* 利用規約CSS */
.content-inner-form p{
  color: #333333;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: left !important;
  margin: 0px 32px;
  padding-bottom: 1em;
}
.content-inner-form > p span{
  vertical-align: -6px;
  padding-left: 7em;
}
.terms-wrap{
  text-align: center;
  margin-bottom: 3em;
}
.terms-wrap small{
  font-size: 1.3rem;
  color: #000;
  display: block;
  padding-top: 1em;
}
.textFrame-registerForm {
  border: 1px solid #dfdfdf;
  color: #333333;
  height: 175px;
  margin: 25px 0 0;
  overflow-y: scroll;
  padding: 20px 15px 0;
}
.textFrame-registerForm-inner {
  overflow: hidden;
  margin-bottom: 30px;
}
.textFrame-registerForm-inner  h3{
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 0.5em;
}
.textFrame-registerForm-inner  h4,
.textFrame-registerForm-inner  > ul{
  margin-top: 0.5em;
}
.textFrame-registerForm-inner  h4{
  font-size: 1.2rem;
}
.textFrame-registerForm-inner  h3,
.textFrame-registerForm-inner  h4,
.textFrame-registerForm-inner ul li{
  color: #191919;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", sans-serif;
  line-height: 1.8;
}
.textFrame-registerForm-inner ul li{
  font-size: 1.2rem;
  margin: 0;
}
.textFrame-registerForm-inner p {
  font-size: 1.2rem;
  margin: 0;
}
.textFrame-registerForm-inner  ul li span{
  display: block;
  margin-top: 0.5em;
}
.textFrame-registerForm-inner > ul > li{
  margin-top: 0.5em;
}
.textFrame-registerForm-inner > ul > li > ul > li {
  font-size: 1.2rem;
  text-indent: -2em;
  padding-left: 3em;
  margin-top: 0.5em;
}
.formInputCheckBox-label {
  color: #000;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 26px;
  position: relative;
  vertical-align: middle;
}
.formInputCheckBox-label::before {
  background: #fff;
  border: 2px solid #bebebe;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: -1px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  width: 20px;
  box-sizing: border-box;
}
.formInputCheckBox-input:checked + .formInputCheckBox-label::before {
  border: 2px solid #E84735;
}
.formInputCheckBox-input:checked + .formInputCheckBox-label::after {
  border-bottom: 2px solid #E84735;
  border-right: 2px solid #E84735;
  content: "";
  height: 14px;
  left: 6px;
  position: absolute;
  top: 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
  box-sizing: border-box;
}
/* //利用規約CSS */

.cart_info_inner {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  .cart_info_top {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 36px 0;
    margin-bottom: 28px;
    dl {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &.point_get {
        color: #d3402c;
      }
    }
  }
  .cart_info_bottom {
    margin-bottom: 60px;
    dl {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1;
    }
  }
  .btn_blk {
    .btn_submit {
      margin-bottom: 25px;
      input[type="submit"] {
        display: block;
        width: 100%;
        border: 1px solid #d3402c;
        background-color: #d3402c;
        color: #fff;
        font-size: 1.8rem;
        text-align: center;
        line-height: 80px;
        font-weight: bold;
        transition-duration: .2s;
        &:hover {
          opacity: .8;
        }
      }
    }
    .btn_back {
      a {
        display: block;
        width: 100%;
        border: 1px solid #000;
        font-size: 1.8rem;
        text-align: center;
        line-height: 80px;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (min-width: 1221px) {
body .input_form_link:hover {
  text-decoration: none;
}
}

@media only screen and (min-width: 751px) {
body .input_form_link {
  font-weight: bold;
}
body .input_form_link {
  font-weight: bold;
}
body .input_form_section {
  margin-top: 40px;
}
body .input_form_section_top {
  margin-top: 20px;
  margin-bottom: 60px;
  .input_form_table .input_form_table td {
    padding: 20px 0 5px;
  }
}
body .input_form_text {
  margin-top: 28px;
}
body .input_form_table th {
  display: table-cell;
  width: 225px;
  padding-right: 20px;
}
body .input_form_table td {
  display: table-cell;
  width: 500px;
  padding: 25px 0;
}
body .input_form_table td.has_error {
  padding-bottom: 10px;
}
body {
  .input_text-passwd,
  .input_text-repasswd,
  .input_text-tel,
  .input_text-receipt {
    width: 300px;
  }
}
body {
  .input_text-email,
  .input_text-reemail,
  .input_text-addr1,
  .input_text-addr2,
  .input_text-addr3,
  .input_text-credit {
    width: 350px;
  }
}
body .input_textarea {
  width: 500px;
}
body .input_label {
  font-size: 1.4rem;
}
body .input_error {
  padding-top: 10px;
}
.cart_input_payment_table td {
  padding: 25px 0;
}
.cart_input_dropdown_text {
  padding: 20px 0 20px 20px;
}
.textFrame-registerForm {
  height: 195px;
  margin: 30px 0 0;
  padding: 30px 38px 0;
}
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.cart_info_inner {
  position: static;
  margin-top: 25px;
  .cart_info_top {
    border-top: none;
    border-bottom: none;
    padding: 36px 20px 23px;
    margin-bottom: 0;
    background-color: #eeeeee;
    dl {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
  }
  .cart_info_bottom {
    background-color: #eeeeee;
    padding: 0 20px 36px;
    margin-bottom: 30px;
    dl {
      border-top: 1px solid #000;
      padding-top: 23px;
      font-size: 1.5rem;
    }
  }
  .btn_blk {
    padding: 0 20px;
    .btn_submit {
      margin-bottom: 20px;
      input[type="submit"] {
        font-size: 1.5rem;
        line-height: 70px;
      }
    }
    .btn_back {
      a {
        font-size: 1.5rem;
        line-height: 70px;
      }
    }
  }
}
}

@media only screen and (max-width: 750px) {
body .input_form_title {
  border: none;
  background-color: #ebebeb;
  padding: 7px 20px;
}
body .input_form_title h2 {
  font-size: 1.5rem;
}
body select {
  font-size: 1.2rem;
  height: 36px;
  padding: 0 5px;
  width: 100%;
  + span {
    position: absolute;
    top: 7px;
    right: 12px;
    left: auto;
  }
  &.select_short {
    width: 100%;
  }
  &.select_date {
    width: 36%;
    + .input_form_unit {
      position: static;
    }
  }
}
body .input_form_content {
  padding: 0 20px;
}
body .input_form_section {
  margin-top: 30px;
}
body .input_form_text {
  margin: 0;
}
body .input_form_table th {
  display: block;
  width: 100%;
  padding: 18px 0 8px;
}
body .input_form_table td {
  display: block;
  width: 100%;
  padding-bottom: 18px;
  position: relative;
}
body .input_form_column dd {
  display: block;
  width: 100%;
  position: relative;
}
body .input_form_column dd + dd {
  margin: 20px 0 0;
}
body {
  .input_text,
  .input_text-passwd,
  .input_text-repasswd,
  .input_text-tel,
  .input_text-receipt,
  .input_text-email,
  .input_text-reemail,
  .input_text-addr1,
  .input_text-addr2,
  .input_text-addr3,
  .input_text-zip,
  .cartLoginFormBlock_input,
  .input_text-credit {
    width: 100%;
    height: 36px;
  }
  .input_text-month,
  .input_text-year {
    width: 30%;
    height: 36px;
  }
}
body .input_text-zip {
  width: 150px;
  + .input_button {
    height: 36px;
  }
}
body .input_textarea {
  width: 100%;
}
body .input_label {
  font-size: 1.4rem;
}
body .input_form_link {
  display: block;
  margin-top: 5px;
}
body .input_form_format-right {
  padding: 10px 0;
}
body .input_error {
  padding-top: 8px;
}
.cart_input_payment_table > tbody > tr > td {
  padding: 18px 0;
}
.cart_input_payment_label {
  display: inline-block;
}
.cart_input_dropdown_text {
  padding: 15px 0 0 20px;
}
.cart_input_dropdown_text img {
  max-width: 80%;
  margin-bottom: 18px;
}
.textFrame-registerForm {
  margin: 25px 20px;
}

body {
  .input_form_section_top {
    margin-top: 50px;
    .input_form_content {
      padding: 0;
      .input_form_table {
        th {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ebebeb;
          font-size: 1.5rem;
          padding: 7px 20px;
          .point_total {
            display: flex;
            align-items: center;
            padding: 0;
            span {
              font-size: 1.2rem;
            }
            div {
              font-size: 1.2rem;
              margin-left: 12px;
            }
          }
        }
        td {
          padding: 18px 20px;
          .input_form_table {
            td {
              padding: 14px 0 0 0;
              .input_form_column {
                .input_form_point {
                  input {
                    font-size: 1.6rem;
                    margin-right: 10px;
                  }
                  .input_form_text {
                    font-size: 1.1rem;
                    margin-right: 20px;
                  }
                  .text_large {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }
        .form_border_bottom {
          border: none !important;
        }
      }
    }
  }
  .input_form_column {
    dd {
      &.input_form_coupon {
        .input_text {
          width: 100%;
        }
      }
    }
  }
}
.cart_info_inner {
  position: static;
  margin-top: 25px;
  .cart_info_top {
    border-top: none;
    border-bottom: none;
    padding: 36px 20px 23px;
    margin-bottom: 0;
    background-color: #eeeeee;
    dl {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
  }
  .cart_info_bottom {
    background-color: #eeeeee;
    padding: 0 20px 36px;
    margin-bottom: 30px;
    dl {
      border-top: 1px solid #000;
      padding-top: 23px;
      font-size: 1.5rem;
    }
  }
  .btn_blk {
    padding: 0 20px;
    .btn_submit {
      margin-bottom: 20px;
      input[type="submit"] {
        font-size: 1.5rem;
        line-height: 70px;
      }
    }
    .btn_back {
      a {
        font-size: 1.5rem;
        line-height: 70px;
      }
    }
  }
}
}
